
.meeting{
  height: 100vh;
  padding-top: 70px;
}

.meeting-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 30px;
  background-color: rgba(49, 76, 150, 0.173); /* Slight transparency */
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  text-align: center;
}

.toggle-buttons {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.toggle-button {
  width: 45%;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #e8ebf3;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button.active {
  background-color: #4b59f7;
  color: white;
}

.toggle-button:hover {
  background-color: #4b59f7;
}

.meeting-form input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s;
}

.meeting-form input:focus {
  border-color: #3444f5ba;
  outline: none;
}

.submit-button {
  width: 100%;
  padding: 12px;
  margin-top: 15px;
  background-color: #272f89;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #3a6dd6;
}

@media (max-width: 768px) {
  .meeting-container {
    padding: 20px;
    max-width: 300px;
  }

  .meeting-form input{
    width: 250px;
  }

  .toggle-buttons {
    flex-direction: column;
  }

  .toggle-button {
    width: auto;
    margin-bottom: 10px;
  }
  
.submit-button{
  width: 280px;
}
}