.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 300px; 
    text-align: center; 
    position: relative; 
}

.modal-content h2 {
    margin-bottom: 15px;
}

.modal-content input {
    width: 220px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.modal-content button {
    background-color: #007bff;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
}

.modal-content button:hover {
    background-color: #0056b3; 
}

.message {
    margin-top: 10px;
    color: rgb(64, 57, 255); 
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px; 
    cursor: pointer;
    font-size: 24px; 
    color: white;
}
