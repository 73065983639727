
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-card {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 30px;
  width: 300px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
}

.login-header h2 {
  margin-bottom: 20px;
  font-size: 22px;
}

.logo {
  width: 50px;
  margin-bottom: 15px;
}


.login-form {
  margin-bottom: 20px;
}

.input-field {
  width: 93%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  margin-bottom: 15px;
  background: rgba(198, 183, 183, 0.268);
  color: rgb(0, 0, 0);
}

.continue-btn {
  width: 300px;
  padding: 12px;
  background-color: #574bf7;;
  border: none;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 15px;
}

.continue-btn:hover {
  background-color: #4e42f0;;
}

.alternative-login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alt-btn {
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
}

.google-btn {
  background-color: rgba(66, 133, 244, 0.1);
  color: white;
}

.microsoft-btn {
  background-color: rgba(0, 120, 212, 0.1);
  color: white;
}

.alt-btn img {
  width: 20px;
  margin-right: 10px;
}

.alt-btn:hover {
  background-color: rgba(255, 255, 255, 0.2);
}


.signup-section p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.signup-section a {
  color: #3366ff;
  text-decoration: none;
}

.signup-section a:hover {
  text-decoration: underline;
}
