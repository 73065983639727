
.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 20px 20px 20px;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  background: url('./wave-haikei\ \(2\).svg') no-repeat center center/cover;
}


.user-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
  background-color: #546dde21;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  color: white;
  width: auto;
}

.user-avatar {
  font-size: 3rem;
  color: #007bff;
}

.user-info h2 {
  font-size: 1.5rem;
  color: #ffffff;
}


.contact-sidebar {
  position: fixed;
  top: 64px;
  right: -320px;
  width: 300px;
  height: 100%;
  background-color: #000000;
  color: #fff;
  transition: right 0.4s ease-in-out;
  padding: 20px;
  box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.contact-sidebar.open {
  right: 0;
}

.toggle-contact-btn {
  background-color: #0062cc;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 25px;
  margin-bottom: 20px;
}

.contact-list h3 {
  margin-bottom: 15px;
}

.contact-list ul {
  list-style: none;
}

.contact-list li {
  margin: 10px 0;
}


.features-grid {
  gap: 20px;
  width: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 30px;
}

.feature-card {
  background-color: #11549263;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  width: 200px;
  margin: 10px;
  color: inherit;
  text-decoration: none;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.user-avatar{
  height: 50px;
  width: 50px;
  color: #007bff;
  border-radius: 50%;
}

.feature-icon {
  font-size: 5rem;
  margin-bottom: 5px;
  margin-left: 10px;
}

.feature-card h3 {
  font-size: 1.2rem;
  color: #007bff;
  margin-bottom: 10px;
  text-decoration: none;
}

.feature-card p {
  font-size: 1rem;
  color: #ffffff;
  text-decoration: none;
}


@media (max-width: 768px) {
  .profile-page{
    overflow-x: hidden;
  }
  .user-info {
    width: 80%;
    position: relative;
    right: 10px;
    border-radius: 20px;
    top: 0px;
    bottom: 20px;
    gap: 20px;
    right: 20px;
  }

  .user-info h2{
    font-size: 1.2rem;
  }

  .profile-page{
    height: 140vh;
  }

  .features-grid {
    padding-top: 0;
    height: 100vh;
  }
  .feature-card{
    width: 80%;
    position: relative;
    right: 20px;
  }
}