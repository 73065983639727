/* Import fonts */
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');



.info-sec {
    color: #fff;
    padding: 0;
    background: url('./wave-haikei\ \(2\).svg') no-repeat center center/cover;
    height: 100vh; 
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1s ease-in-out;
    margin-top: 0; 
}

.light-bg {
    background: #f9f9f9;
    color: #000;
}


.container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 15px;
    font-family: "Fredoka", sans-serif;
}


.info-row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    animation: fadeInUp 1s ease-in-out;
}

.row-reverse {
    flex-direction: row-reverse;
}

.info-column {
    flex: 1;
    text-align: start;
    width: 100%;
    font-family: 'Fredoka', sans-serif; 
    font-weight: 500; 
    color: #f5f5f5; 
}

.text-wrapper {
    max-width: 540px;
    margin: 0 auto;
    position: relative;
    bottom: 30px;
}


.top-line {
    color: #4b59f7;
    font-size: 18px;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    font-weight: 700; 
    margin-bottom: 16px;
}


.heading {
    font-size: 2.8rem; 
    font-weight: 700;
    margin-bottom: 24px;
    line-height: 1.2;
    color: #fff;
}

.subtitle {
    font-size: 1.1rem; 
    font-weight: 400; 
    margin-bottom: 35px;
    color: #e0e0e0; 
    line-height: 1.6;
}

.btn {
    padding: 14px 32px;
    font-size: 16px;
    color: #fff;
    background: #4b59f7;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-weight: 700; 
    font-family: "Fredoka", sans-serif;
    transition: all 0.3s ease-in-out;
}

.btn:hover {
    transform: translateY(-3px);
}


.img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.img {
    width: 100%;
    max-width: 400px;
    border-radius: 15px;
    transition: transform 0.5s ease, box-shadow 0.5s ease;
}


/* Add Animations */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Styles */
@media (max-width: 768px) {
    .info-sec {
        height: auto; 
        padding-top: 100px;
    }

    .info-column {
        width: auto; 
    }

    .text-wrapper{
        text-align: start;
        max-width: 90%;
    }

    .heading {
        font-size: 2rem; 
    }

    .subtitle {
        font-size: 1rem;
    }

    .btn {
        padding: 12px 28px;
        font-size: 14px;
    }

    .img {
        width: auto;
    }
}
