.features-container {
  background-color: #000000;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 100vh;
  border-right: 1px solid #ffffff;
  position: relative;
}

.features-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.feature-item {
  width: auto;
  max-width: 50px;
  font-size: 18px;
  margin: 10px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.2s ease;
}

.feature-item:hover {
  transform: translateY(-2px);
}

.feature-icon {
  font-size: 30px;
  color: #4CAF50;
  margin-right: 15px;
}

.feature-info {
  flex: 1;
}

.feature-description {
  position: absolute;
  bottom: 3px;
  left: 60px;
  padding: 30px;
  font-size: 10px;
  background-color: #3f3f3fad;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
}


.feature-item:hover .feature-description {
  opacity: 1;
  font-size: 10px;
}