@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


.footer {
    background-color: #4b59f742;
    color: #fff;
    padding: 40px 20px;
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}

/* Footer Container */
.footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    margin: 0 auto;
    gap: 30px;
}

.footer-section {
    flex: 1;
    min-width: 280px;
    text-align: start;
}

.footer-section h2 {
    font-size: 1.5rem;
    margin-bottom: 5px;
    font-weight: 700;
}


.about-developer p {
    font-size: 1rem;
    line-height: 1.5;
    color: #ddd;
    text-align: start;
}

.footer-section ul{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: start;
  gap: 2px;
}

.footer-section ul li a{
    color: #f2f2f2;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s;
}

.about-project p {
    font-size: 1rem;
    line-height: 1.6;
    color: #ddd;
}


.footer-navigation ul {
    list-style-type: none;
    padding: 0;
}

.footer-navigation ul li {
    margin-bottom: 10px;
}

.footer-navigation ul li a {
    text-decoration: none;
    font-size: 1rem;
}


.footer-bottom {
    margin-top: 20px;
    font-size: 0.9rem;
    color: #bbb;
    border-top: 1px solid #444;
    padding-top: 10px;
    text-align: center;
}

 .footer-bottom p a{
        text-decoration: none;
        color: white;
    } 


@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: start;
        text-align: start;
        gap: 5px;
    }

    .footer-section {
        max-width: 100%;
        align-items: start;
        text-align: start;
    }

    .footer-section ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        text-align: start;
        gap: 2px;
    }

    .footer-link{
    text-align: start;
      margin-left: 8px;
    }

    .footer-bottom{
        text-align: center;
    }

    .about-project{
        gap: 0px;
    }
}
