.navbar-brand {
  display: flex;
  align-items: center;
  gap: 10px;
}

.nav-logo {
  height: 35px;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 15px;
}

.profile-photo {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }
}
